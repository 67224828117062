import { FC, memo } from 'react'

import { Text, Button } from '@cash-me/react-components'

import { Grid, Col } from 'components/grid'
import { Table } from 'components/table'
import useWindowSize from 'hooks/useWindowSize'

import { ConsortiumFinancingProps } from './consortium-financing.model'
import { tableConsortium, tableFinancing } from './data'
import * as S from './styles'

const ConsortiumFinancing: FC<ConsortiumFinancingProps> = memo(({ onClick }) => {
  const { isMobile } = useWindowSize()

  return (
    <S.ConsortiumFinancing>
      <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
        <Col col={{ tablet: 5, desktop: 5 }} start={{ tablet: 2, desktop: 2 }}>
          <Text as="h2" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
            Consórcio {' '}
            <Text as="span" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
              ou {' '}
            </Text>
            Financiamento?
          </Text>
          <Text color="var(--colors-neutral-text-weak)">Existem algumas possibilidades, confira:</Text>
          {!isMobile && 
            <Button size="lg" onClick={onClick} iconName="arrowRight">
              Saiba mais
            </Button>
          }
        </Col>
        <Col col={{ tablet: 6, desktop: 6 }} start={{ tablet: 7, desktop: 7 }}>
        <S.TableContainer>
          <Table tableData={tableConsortium} />
            <Table
              backgroundHeader='neutral-border-default'
              backgroundData='neutral-background-default'
              tableData={tableFinancing}
            />
          </S.TableContainer>
          {isMobile && 
            <Button size="lg" onClick={onClick} iconName="arrowRight">
              Saiba mais
            </Button>
          }
        </Col>
      </Grid>
    </S.ConsortiumFinancing>
  )
})

ConsortiumFinancing.displayName = 'ConsortiumFinancing'

export default ConsortiumFinancing
