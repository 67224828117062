import styled from 'styled-components'

import { theme } from 'src/styles'

const { space, colors, radii } = theme

interface WrapperProps {
  readonly backgroundHeader: string
  readonly backgroundData: string
}

export const Wrapper = styled.table<WrapperProps>`
  border-spacing: 0;
  border-collapse: separate;

  th {
    background: ${props => colors[props.backgroundHeader]};
  }

  td {
    background: ${props => colors[props.backgroundData]};
  }

  th,
  td {
    padding: ${space['spacing-sm']};
    border: 1px solid ${colors['neutral-border-default']};
  }

  thead {
    th:first-child {
      border-top-left-radius: ${radii['corner-radius-md']};
    }

    th:last-child {
      border-top-right-radius: ${radii['corner-radius-md']};
    }
  }

  tbody tr:last-child {
    td:first-child {
      border-bottom-left-radius: ${radii['corner-radius-md']};
    }

    td:last-child {
      border-bottom-right-radius: ${radii['corner-radius-md']};
    }
  }
`
