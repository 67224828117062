import styled from 'styled-components'

import { theme } from 'styles/theme'

const { space, media } = theme

export const ConsortiumFinancing = styled.section`
  position: relative;
  width: 100%;
  padding: ${space['spacing-xxxl']} 0;

  h2 + p {
    margin: ${space['spacing-md']} 0;
  }
`

export const TableContainer = styled.div`
  table {
    margin-bottom: ${space['spacing-md']};
  }

  @media (${media['tablet']}) {
    display: flex;

    table {
      width: 50%;
    }

    td {
      padding: ${space['spacing-xxs']} ${space['spacing-sm']};
      height: 60px;
      display: flex;
      align-items: center;
    }

    table:first-child {
      thead {
        th:last-child {
          border-top-right-radius: unset;
        }
      }

      tbody tr:last-child {
        td:first-child {
          border-bottom-right-radius: unset;
        }
      }
    }

    table:last-child {
      thead {
        th:first-child {
          border-top-left-radius: unset;
        }
      }

      tbody tr:last-child {
        td:first-child {
          border-bottom-left-radius: unset;
        }
      }
    }
  }
`

export const TableHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${space['spacing-sm']};
`