import { Icon, Text } from "@cash-me/react-components"

import { TableHeading } from "./styles"

export const tableConsortium = {
  headers: [
    <TableHeading key="1">
      <Icon name="piggyBank" color="var(--colors-primary-icon-strong)" />
      <Text as="span" color="var(--colors-primary-text-strong)">CONSÓRCIO</Text>
    </TableHeading>
],
  content: [
    [
      <Text key="1" as="span" color="var(--colors-neutral-text-default)">
        Custo total menor, pois tem taxa de administração fixa, e não juros
      </Text>
    ],
    [
      <Text key="2" as="span" color="var(--colors-neutral-text-default)">
        <b>Diferencial CashMe:</b> 
        {' '}
        Não precisa de entrada
      </Text>
    ],
    [
      <Text key="3" as="span" color="var(--colors-neutral-text-default)">
        Parcelas menores
      </Text>
    ],
    [
      <Text key="4" as="span" color="var(--colors-neutral-text-default)">
        É investimento
      </Text>
    ]
  ]
}

export const tableFinancing = {
  headers: [
    <TableHeading key="1">
      <Icon name="loan" color="var(--colors-neutral-icon-default)" />
      <Text as="span" color="var(--colors-neutral-text-default)">FINANCIAMENTO</Text>
    </TableHeading>
],
  content: [
    [
      <Text key="1" as="span" color="var(--colors-neutral-text-default)">
        Custo total maior, pois juros são gerados a cada parcela
      </Text>
    ],
    [
      <Text key="1" as="span" color="var(--colors-neutral-text-default)">
        Precisa de entrada
      </Text>
    ],
    [
      <Text key="1" as="span" color="var(--colors-neutral-text-default)">
        Parcelas maiores
      </Text>
    ],
    [
      <Text key="1" as="span" color="var(--colors-neutral-text-default)">
        É dívida
      </Text>
    ],
  ]
}